<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    isIphone?: boolean;
  }>(),
  {
    isIphone: false,
  },
);

const subtext = computed(() => {
  if (props.isIphone) {
    return t("mobile-app-promo.app-buttons.qr-code-subtext-iphone").replace(
      /\n/g,
      "<br>",
    );
  }
  return t("mobile-app-promo.app-buttons.qr-code-subtext-android").replace(
    /\n/g,
    "<br>",
  );
});
</script>

<template>
  <div class="content-container">
    <div class="qr-code-container">
      <div class="qr-code">
        <nuxt-img
          v-if="isIphone"
          class="qr-code-image"
          src="/img/qr-iphone.png"
          alt="qr-code"
        />
        <nuxt-img
          v-else
          class="qr-code-image"
          src="/img/qr-android.png"
          alt="qr-code"
        />
      </div>
    </div>
    <div
      class="text-container"
      v-html="subtext"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.content-container {
  @apply flex flex-col  w-[174px] h-[228px] bg-fg-primary pt-2.5 rounded-[20px];
}
.qr-code-image {
  @apply self-end justify-self-center w-[120px] h-[120px];
}
.qr-code-container {
  @apply h-[154px] w-[154px] rounded-[12px] mx-auto;
  @apply flex items-center justify-center;
  background-color: rgba(255, 255, 255, 0.09);
}
.text-container {
  @apply font-normal leading-5 text-fg-contrast text-center py-2;
}
</style>
