<script setup lang="ts">
import TooltipContent from "~/components/MobileAppsButtons/TooltipContent.vue";

const links = {
  apple: {
    to: "https://apps.apple.com/app/pst-net-cards-cashback/id6450429721",
    img: "/img/download-on-the-app-store.svg",
  },
  android: {
    to: "https://play.google.com/store/apps/details?id=com.pstnet.app&referrer=landing",
    img: "/img/download-on-google.svg",
  },
};

enum DeviceType {
  APPLE = "apple",
  ANDROID = "android",
}

const isApple = ref<boolean>(true);
const isTooltipVisible = ref<boolean>(false);

const onMouseEnter = (device: DeviceType) => {
  isApple.value = device === DeviceType.APPLE;
  isTooltipVisible.value = true;
};

const onMouseLeave = () => {
  isTooltipVisible.value = false;
};
</script>

<template>
  <div class="mobile-apps-buttons">
    <div class="link-container">
      <div class="tooltip-container">
        <transition name="fade-slide-up">
          <TooltipContent
            v-if="isTooltipVisible && isApple"
            :is-iphone="isApple"
          />
        </transition>
      </div>
      <nuxt-link :to="links.apple.to">
        <nuxt-img
          @mouseenter="onMouseEnter(DeviceType.APPLE)"
          @mouseleave="onMouseLeave()"
          :src="links.apple.img"
          width="144"
        />
      </nuxt-link>
    </div>
    <div class="link-container">
      <div class="tooltip-container">
        <transition name="fade-slide-up">
          <TooltipContent
            v-if="isTooltipVisible && !isApple"
            :is-iphone="isApple"
          />
        </transition>
      </div>
      <nuxt-link :to="links.android.to">
        <nuxt-img
          @mouseenter="onMouseEnter(DeviceType.ANDROID)"
          @mouseleave="onMouseLeave()"
          :src="links.android.img"
          width="159"
        />
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mobile-apps-buttons {
  @apply flex gap-2.5;
}
.link-container {
  @apply relative;
}

.tooltip-container {
  @apply absolute bottom-full left-1/2 -translate-x-1/2 mb-2;
}
</style>
